@import '../../assets/css/variables.scss';

.go-back {
  margin-bottom: 30px;
  .container {
    &:hover {
      cursor: pointer;
    }
    display: flex;
    align-items: center;

    p {
      color: $primary;
    }
  }
}
