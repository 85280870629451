@import '../../assets/css/variables.scss';

.investment-admin {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    text-align: start;
    margin-bottom: 32px;
    color: $primary;
    h1 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 78px;
    }
  }
  .buttons {
    margin-bottom: 50px;
    button {
      height: 50px;
      border-radius: 10px;
    }
    .cancel {
      background-color: $warning;
      max-width: 200px;
    }
  }

  .table-container {
    h2 {
      color: $primary;
      text-align: center;
      border-bottom: solid 2px $secondary;
      margin: 0 50px;
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }

  .balance-card {
    background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      $primary 100%
    );
    border-radius: 21px;
    padding: 48px 89px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $default;
    margin-bottom: 32px;

    .title-container {
      padding: 0;
      margin-bottom: 40px;
      border-bottom: solid 1px $default;
    }

    .amount,
    .title {
      color: $default;
      font-weight: $regular;
      font-size: $large;
    }

    .currency {
      color: $warning;
      font-weight: $regular;
      font-size: $large;
    }

    .currency-container {
      padding: 0;
      padding-bottom: 5px;
      border-bottom: solid 1px $default;
    }
    .first-child {
      margin-bottom: 15px;
    }
  }
  .cripto-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 8px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .table-card table .input-container {
    width: 180px;
  }
}
