@import '../../assets/css/variables.scss';

.balance-card {
  background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      #5d5fef 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 10px;
  padding: 17px 20px;
  margin-bottom: 15px;

  .title-container {
    padding: 0;
    margin-bottom: 40px;
    border-bottom: solid 1px $default;
  }

  .amount,
  .title {
    color: $default;
    font-weight: $regular;
    font-size: $medium;
  }

  .currency {
    color: $warning;
    font-weight: $regular;
    font-size: $medium;
  }

  .currency-container {
    padding: 0;
    padding-bottom: 5px;
    border-bottom: solid 1px $default;
  }
  .first-child {
    margin-bottom: 15px;
  }
}
