@import '../../assets/css/variables.scss';

.home-company {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    .welcome {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      .line {
        border-left: 2px solid $primary;
        margin: 0 10px;
        height: 30px;
      }
      .profile {
        background: radial-gradient(
            71.19% 285.35% at 0% 129.17%,
            #b16b8f 0%,
            $primary 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */;
        border-radius: 0px 10px 10px 0px;
        height: 35px;
        color: $default;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    h1 {
      color: $primary;
    }

    .sub-title {
      color: $primary-shade1;
    }
  }

  .table-container {
    h2 {
      color: $primary;
      text-align: center;
      border-bottom: solid 2px $secondary;
      margin: 0 120px;
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    button {
      width: 264px;
      height: 46px;
      left: 0px;
      top: 0px;
      background: linear-gradient(0deg, $primary, $primary);
      border-radius: 10px;
    }
  }

  table {
    thead {
      border-bottom: 2px solid $default;
      th {
        padding: 20px 0;
      }
    }
    tbody {
      .name {
        padding-left: 15px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        p {
          padding: 5px;
        }
      }
      .actions {
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .action {
        background: transparent;
        color: $muted;
        width: auto;
        padding: 10px;
      }
    }
  }
}
