@import '../../assets/css/variables.scss';

.confirm-component {
  img {
    margin-top: 30px;
    width: 120px;
    margin-bottom: 20px;
  }
  p {
    color: $default;
    margin-bottom: 30px;
  }
  .center-content {
    display: flex;
    justify-content: center;
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    button {
      width: 100px;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 34px;
    }
    .cancel {
      background-color: $warning;
      margin-right: 10px;
    }
    .save {
      background: linear-gradient(0deg, $primary, $primary);
    }
  }
}
