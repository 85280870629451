@import '../../assets/css/variables.scss';

.process-card {
  background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      #5d5fef 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 10px;
  padding: 17px 20px;
  margin-bottom: 15px;

  .title-container {
    padding: 0;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
  }

  .title {
    color: $default;
    font-weight: $regular;
    font-size: $large;
    text-align: center;
  }

  .amount {
    color: $warning;
    font-weight: $bold;
    font-size: $xlarge;
    text-align: center;
    margin-right: 5px;
  }

  .currency {
    color: $default;
    font-weight: $bold;
    font-size: $xlarge;
  }

  .currency-container {
    padding: 0;
    padding-bottom: 5px;
    border-bottom: solid 1px $default;
    display: flex;
    justify-content: center;
    margin-bottom: 7px;
  }
  .first-child {
    margin-bottom: 15px;
  }
}
