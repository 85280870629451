// Color palette
$primary: #5d5fef;
$primary-shade1: #8183f4;
$primary-shade2: #bebfee;
$secondary: #7c1d9d;
$secondary-shade1: #aa3ecf;
$secondary-shade2: #d771fa;
$success: #47d881;
$warning: #eca336;
$muted: #c0c0c0;
$danger: #bc2b2b;
$default: #ffffff;

// sizes
$xsmall: 10px;
$small: 12px;
$medium: 16px;
$large: 20px;
$xlarge: 26px;
$xxlarge: 32px;
$xxxlarge: 40px;

$bold: 700;
$light: 300;
$fmedium: 500;
$regular: 400;