.modal-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .modal_lg {
    width: 80%;
    max-height: 80%;
  }
  .modal_xs {
    width: 60%;
    max-height: 60%;
  }
  .modal {
    z-index: 2;
    background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      #5d5fef 100%
    );
    opacity: 1;
    border-radius: 20px;
    overflow-y: auto;

    .modal_body {
      padding: 20px;
    }
  }
  @media only screen and (max-width: 64em) {
    .modal_lg {
      width: 90%;
      max-height: 90%;
    }
    .modal_xs {
      width: 90%;
      max-height: 90%;
    }
  }
}
