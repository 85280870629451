@import '../../assets/css/variables.scss';

.create-user {
  h1 {
    text-align: center;
    color: $default;
    margin-bottom: 34px;
    margin-top: 50px;
  }
  .input-container {
    margin-bottom: 29px;
    padding: 0 10px;
    label {
      margin-bottom: 16px;
      color: $default;
    }
    input {
      display: flex;
      padding: 3px 5px;
      width: 100%;
      height: 30px;
      background: transparent;
      border: none;
      border-bottom: 1px solid $default;
      outline: none;
      color: $default;
      font-weight: $light;
      font-size: $large;
      font-family: 'Outfit';
      font-style: normal;

      &::placeholder {
        color: $default;
        font-weight: $fmedium;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    button {
      width: 100px;
      height: 50px;
      border-radius: 10px;
      margin: 34px 10px;
    }
    .save {
      background: linear-gradient(0deg, $primary, $primary);
    }
    .cancel {
      background-color: $warning;
    }
  }
}
