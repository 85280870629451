@import '../../assets/css/variables.scss';

.retirement-date {
  background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      #5d5fef 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 10px;
  padding: 17px 20px;

  .retirement-date_title-container {
    padding: 0;
    margin-bottom: 10px;
    border-bottom: solid 1px $default;
  }

  .retirement-date_title {
    color: $default;
    font-weight: $regular;
    font-size: $large;
  }

  .retirement-date_date-container {
    margin-bottom: 20px;
    .retirement-date_date-title {
      color: $default;
      font-weight: $regular;
      font-size: $small;
    }
    .retirement-date_date-description {
      font-weight: $light;
      font-size: $large;
      color: $warning;
    }
  }

  .button-warning-container {
    display: flex;
    justify-content: center;
    .button-warning {
      font-size: $medium;
    }
  }
}
