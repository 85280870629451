@import '../../assets/css/variables.scss';

.login {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    text-align: center;
    margin-bottom: 32px;

    h2 {
      color: $primary;
      margin-bottom: 20px;
    }
    p {
      border-bottom: 2px solid $secondary;
    }
  }

  .login-container {
    background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      $primary 100%
    );
    border-radius: 21px;
    padding: 21px;

    .input-container {
      width: 100%;
      margin-bottom: 29px;
      label {
        margin-bottom: 16px;
        color: $default;
      }
    }
    .user-logo {
      height: 80px;
      width: 80px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 39px;
    }
    input {
      // display: flex;
      padding: 3px 5px;
      width: 100%;
      height: 30px;
      background: transparent;
      border: none;
      border-bottom: 1px solid $default;
      outline: none;
      color: $default;
      font-weight: $light;
      font-size: $large;

      &::placeholder {
        color: $default;
        font-weight: $fmedium;
      }
    }

    button {
      width: 100px;
      height: 50px;
      background: linear-gradient(0deg, $primary, $primary);
      border-radius: 10px;
      margin-bottom: 21px;
    }
  }
}
