/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('Outfit/static/Outfit-Thin.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('Outfit/static/Outfit-ExtraLight.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('Outfit/static/Outfit-Light.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Outfit/static/Outfit-Regular.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('Outfit/static/Outfit-Medium.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('Outfit/static/Outfit-SemiBold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Outfit/static/Outfit-Bold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('Outfit/static/Outfit-ExtraBold.ttf') format('truetype');
}
/* latin */
@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('Outfit/static/Outfit-Black.ttf') format('truetype');
}
