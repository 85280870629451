@import '../../assets/css/variables.scss';

.home {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    h1 {
      color: $primary;
      margin-bottom: 20px;
    }

    .sub-heading {
      color: $secondary;
      margin-bottom: 20px;
    }

    .description {
      color: $primary;
      margin-bottom: 20px;
    }

    .gradient {
      margin-bottom: 15px;
    }
  }

  .table-container {
    h2 {
      color: $primary;
      text-align: center;
      border-bottom: solid 2px $secondary;
      margin: 0 50px;
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }
  .cripto-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 8px;
    }
  }
}
