@import '../../assets/css/variables.scss';

.user-investment {
  h1 {
    color: $primary;
    margin-bottom: 18px;
  }
  h2 {
    color: $secondary;
  }
  .title-container {
    margin-bottom: 30px;
  }
  .title-icon-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    img {
      height: 250px;
    }
  }
  .balance-container {
    margin-bottom: 30px;
    .warning {
      color: $warning;
    }
    .warning-text {
      color: $secondary;
      font-weight: $bold;
      font-size: $xlarge;
    }
  }
  .title-icon-container-normalizer {
    width: 100%;
  }

  .table-container {
    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}
