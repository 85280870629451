@import '../../assets/css/variables.scss';

.footer {
  padding: 60px 0 30px 0;
  background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      #5d5fef 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  .logo-container {
    padding-left: 8px;
  }
  .logo {
    height: 80px;
    margin-right: 30px;
  }
  .links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 38px;
    a {
      &:hover {
        opacity: 0.7;
      }
      font-size: $large;
      font-weight: $fmedium;
      text-decoration: none;
      color: $default;
      font-weight: $light;
    }
  }
  .social-container {
    display: flex;
    justify-content: flex-end;
    a {
      &:hover {
        opacity: 0.7;
      }
      text-decoration: none;
      img {
        height: 22px;
        width: 22px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  @media only screen and (max-width: 64em) {
    .logo-container {
      margin-bottom: 30px;
    }
    .links {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 10px;
        width: 100%;
      }
    }
    .social-container {
      justify-content: flex-start;
      a {
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}
