@import '../../assets/css/variables.scss';

.home-admin {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    .welcome {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      .line {
        border-left: 2px solid $primary;
        margin: 0 10px;
        height: 30px;
      }
    }
    h1 {
      color: $primary;
      font-size: 40px;
    }

    .sub-title {
      color: $primary-shade1;
      font-size: 32px;
      font-weight: bold;
      background: radial-gradient(
        71.19% 285.35% at 0% 129.17%,
        #b16b8f 0%,
        #5d5fef 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .table-container {
    h2 {
      color: $primary;
      text-align: center;
      border-bottom: solid 2px $secondary;
      margin: 0 120px;
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }

  .buttons {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    margin-bottom: 15px;
    button {
      width: 264px;
      height: 46px;
      left: 0px;
      top: 0px;
      background: linear-gradient(0deg, $primary, $primary);
      border-radius: 10px;
    }
  }

  .tabs {
    display: flex;
    align-content: flex-start;
    padding-left: 120px;
    button {
      margin-right: 10px;
      max-width: 120px;
      background: radial-gradient(
        90% 550% at 0% 100%,
        #8e66b6 0%, 
        #5D5FEF 100%
      );
      border-radius: 10px 10px 0px 0px;
    }
    .selected {
      background: #5D5FEF;
    }
  }

  table {
    thead {
      border-bottom: 2px solid $default;
      th {
        padding: 20px 0;
      }
    }
    tbody {
      .name {
        padding-left: 15px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
        p {
          padding: 5px;
        }
      }
      .actions {
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .action {
        background: transparent;
        color: $muted;
        width: auto;
        padding: 10px;
      }
    }
  }
}
