@import '../../assets/css/variables.scss';

.header {
  .sub-title {
    color: $primary-shade1;
  }
  position: relative;
  margin-bottom: 32px;
  .background {
    width: 100%;
  }
  .logo {
    height: 80px;
  }
  .user-logo {
    height: 70px;
    width: 70px;
    object-fit: cover;
    border-radius: 50%;
  }
  .header-container {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -85px;
  }

  /* Links inside the dropdown */
  .dropdown-content div {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content div:hover {
    background-color: #ddd;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  @media only screen and (max-width: 64em) {
    .background {
      display: none;
    }
    .header-container {
      padding: 30px 0;
      position: relative;
      background: radial-gradient(
        71.19% 285.35% at 0% 129.17%,
        #b16b8f 0%,
        #5d5fef 100%
      );
      margin-bottom: 30px;
    }
  }
}
