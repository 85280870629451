@import '../../assets/css/variables.scss';

.update-profile {
  .hedings {
    margin-bottom: 32px;
  }
  .title-container {
    text-align: center;
    margin-bottom: 32px;
    color: $primary;
    h1 {
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 78px;
    }

  }

  .login-container {
    background: radial-gradient(
      71.19% 285.35% at 0% 129.17%,
      #b16b8f 0%,
      $primary 100%
    );
    border-radius: 21px;
    padding: 21px 89px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $default;

    h1 {
      margin-bottom: 21px;
    }

    .img {
      margin-right: 60px;
    }
    .update-container {
      display: flex;
      align-items: center;
    }

    .input-container {
      margin-bottom: 29px;
      label {
        margin-bottom: 16px;
        color: $default;
      }
    }
    .user-logo {
      height: 80px;
      width: 80px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 39px;
    }
    input {
      display: flex;
      padding: 3px 5px;
      width: 43vw;
      height: 30px;
      background: transparent;
      border: none;
      border-bottom: 1px solid $default;
      outline: none;
      color: $default;
      font-weight: $light;
      font-size: $large;
      font-family: 'Outfit';
      font-style: normal;

      &::placeholder {
        color: $default;
        font-weight: $fmedium;
      }
    }

    .buttons {
      button {
        width: 100px;
        height: 50px;
        border-radius: 10px;
        margin: 34px 10px;
      }
      .save {
        background: linear-gradient(0deg, $primary, $primary);
      }
      .cancel{
        background-color: $warning ;
      }
    }
  }
}
